import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  useUnlinkAcControllerMutation,
  useUnlinkedLinkPowerConsumerMutation,
} from "../../../../../../redux/api/controller/controllerAPI";
import { useLazyCheckIfSmartPlugRegisteredToDRPQuery } from "../../../../../../redux/api/usage-guide/usageGuideAPI";
import AcController from "../../../../../../shared/components/ac-controller/ac-controller";
import CheckIfSmartPlugRegisteredResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/check-if-smart-plug-registered-response-dto";
import { EAcProtocolType } from "../../../../../../shared/oversight-core/enums/ac-protocol-type";
import { EDeviceStatus } from "../../../../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../../../../shared/oversight-core/enums/device-types";
import { ESmartControllerType } from "../../../../../../shared/oversight-core/enums/smart-controller-type";
import { IPowerConsumerView } from "../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import ISmartController from "../../../../../../shared/oversight-core/interfaces/smart-controller";
import AppModal from "../../../../../../shared/oversight-core/ui-elements/app-modal/app-modal";
import MaterialIcon, {
  MtIcon,
} from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";
import ControllerLinkModal from "../controller-link-modal/controller-link-modal";
import DeviceUnlinkModal from "../device-unlink-modal/device-unlink-modal";
import ViewDeviceModalHeader from "./view-device-modal-header/view-device-modal-header";
import styles from "./view-device-modal.module.scss";

interface IProps {
  device: IPowerConsumerView;
  smartPlugController?: ISmartController;
  acController?: ISmartController;
  show: boolean;
  size?: "modal-sm" | "modal-lg" | "modal-md";
  setShowAddUpdateModal: (show: boolean) => void;
  setShowRemoveDevice: (show: boolean) => void;
  onClose: () => void;
  icon: MtIcon;
  isPowerConsumerUpdated?: boolean;
  setIsPowerConsumerUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  spaceClusterId: string;
  spaceId: string;
  updateDeviceStatus?: (
    spaceClusterId: string,
    spaceId: string,
    powerConsumerId: string,
    switchingPowerState: EDeviceStatus,
    isFromViewDeviceModal?: boolean
  ) => void;
  onIncrease?: (event: React.MouseEvent<HTMLElement>) => void;
  onDecrease?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ViewDeviceModal = (props: IProps) => {
  const { smartPlugController, acController, device, onIncrease, onDecrease } =
    props;

  const [controllerLinkModal, setControllerLinkModal] = useState(false);
  const [deviceUnlinkModal, setDeviceUnlinkModal] = useState(false);
  const [scheduleIdList, setScheduleIdList] = useState<string[]>([]);
  const [linkSmartDeviceType, setLinkSmartDeviceType] =
    useState<ESmartControllerType>(ESmartControllerType.SMART_PLUG);
  const [unlinkSmartDeviceType, setUnlinkSmartDeviceType] =
    useState<ESmartControllerType>(ESmartControllerType.SMART_PLUG);
  const [isRegisteredDRP, setIsRegisteredDRP] = useState(false);
  const [isRemoveDeviceFromDRP, setIsRemoveDeviceFromDRP] = useState(false);

  const [unlinkedLinkPowerConsumer] = useUnlinkedLinkPowerConsumerMutation();
  const [unlinkAcController] = useUnlinkAcControllerMutation();
  const [checkIfSmartPlugRegisteredToDRP] =
    useLazyCheckIfSmartPlugRegisteredToDRPQuery();

  const unlinkDevice = (
    spaceClusterId: string,
    spaceId: string,
    smartPlugId: string,
    linkedPowerConsumerId: string,
    excludedSemiAutomatedSchedules: string[]
  ) => {
    if (unlinkSmartDeviceType === ESmartControllerType.AC_CONTROLLER) {
      unlinkAcController({
        spaceClusterId,
        spaceId,
        acControllerId: smartPlugId,
        linkedAcId: linkedPowerConsumerId,
        excludedSemiAutomatedSchedules,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Device Unlinked Successfully");
          props.setIsPowerConsumerUpdated &&
            props.setIsPowerConsumerUpdated((ps) => !ps);
        })
        .catch(() => {
          showErrorMessage("Device Unlinked Unsuccessful");
        });

      return;
    }

    unlinkedLinkPowerConsumer({
      spaceClusterId,
      spaceId,
      smartPlugId,
      linkedPowerConsumerId,
      excludedSemiAutomatedSchedules,
      keepInProgramme: isRemoveDeviceFromDRP,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device Unlinked Successfully");
        props.setIsPowerConsumerUpdated &&
          props.setIsPowerConsumerUpdated((ps) => !ps);
      })
      .catch(() => {
        showErrorMessage("Device Unlinked Unsuccessful");
      });
  };

  useEffect(() => {
    if (props.show) {
      checkIfSmartPlugRegisteredToDRP({
        spaceClusterId: props.spaceClusterId,
        spaceId: props.spaceId,
        smartPlugId: smartPlugController?.id || "",
      })
        .unwrap()
        .then((res: CheckIfSmartPlugRegisteredResponseDTO) => {
          setIsRegisteredDRP(res.registered);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    props.show,
    props.spaceClusterId,
    props.spaceId,
    smartPlugController?.id,
  ]);

  return (
    <>
      <AppModal show={props.show}>
        <Container fluid>
          <Row className="justify-content-between align-items-center">
            <Col>
              <ViewDeviceModalHeader
                device={device}
                icon={props.icon}
                setShowAddUpdateModal={props.setShowAddUpdateModal}
                setShowRemoveDevice={props.setShowRemoveDevice}
                updateDeviceStatus={props.updateDeviceStatus}
                spaceClusterId={props.spaceClusterId}
                spaceId={props.spaceId}
              />
            </Col>
            <Col xs="auto" className="p-0">
              <MaterialIcon
                icon="close"
                size={28}
                className="cursor-pointer"
                onClick={props.onClose}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={3} className="mt-4 mt-md-0">
              <div className={styles.deviceTitle}>Device Type</div>
              <div className={styles.deviceValue}>{device.deviceType}</div>
            </Col>
            <Col xs={12} md={2} className="mt-4 mt-md-0">
              <div className={styles.deviceTitle}>Brand</div>
              <div className={styles.deviceValue}>{device.brand}</div>
            </Col>
            <Col xs={12} md={2} className="mt-4 mt-md-0">
              <div className={styles.deviceTitle}>Model</div>
              <div
                className={styles.deviceValue}
                style={{
                  wordWrap: "break-word",
                }}
              >
                {device.modelNumber}
              </div>
            </Col>
            <Col xs={12} md={5} className="mt-4 mt-md-0">
              <div className={styles.deviceTitle}>Average Energy Usage (W)</div>
              <div className={styles.deviceValue}>
                {device.powerUsageInWatt}
              </div>
            </Col>
          </Row>
          {device.deviceCategory === EDeviceTypes.AIR_CONDITIONER && (
            <Row>
              <Col className="mt-3">
                <div className={styles.deviceTitle}>Communication Protocol</div>
                <div className={styles.deviceValue}>{device.protocol}</div>
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col className="font-size-14 text-dark font-weight-500">
              Smart Controller
            </Col>
          </Row>
          {props.isPowerConsumerUpdated ? (
            <SpinnerModal
              withOverlay={false}
              show={props.isPowerConsumerUpdated}
              size="sm"
            />
          ) : (
            <>
              {acController?.linkedPowerConsumer || acController?.id ? (
                <Row
                  className={`${styles.socket} align-items-center justify-content-between mt-1 p-2 mx-0`}
                >
                  <Col>
                    <Row className="align-items-center">
                      <Col
                        xs={"auto"}
                        className="text-dark font-size-14 font-weight-500"
                      >
                        {acController?.name}
                      </Col>
                      <Col className="text-light font-size-12 font-weight-400">
                        {acController?.serialKey}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={"auto"}>
                    <MaterialIcon
                      icon="link_off"
                      color="#011F5D"
                      className="cursor-pointer"
                      onClick={() => {
                        setUnlinkSmartDeviceType(
                          ESmartControllerType.AC_CONTROLLER
                        );
                        setDeviceUnlinkModal(true);
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="align-items-center mt-1 g-2">
                  {device.deviceCategory === EDeviceTypes.AIR_CONDITIONER && (
                    <>
                      {device.protocol === EAcProtocolType.OTHER ? (
                        <p className="text-blue font-weight-500 font-size-12">
                          Devices with Other type communication protocol
                          won&apos;t be able to link to an A/C Controller
                        </p>
                      ) : (
                        <Col
                          className="cursor-pointer"
                          onClick={() => {
                            setControllerLinkModal(true);
                            setLinkSmartDeviceType(
                              ESmartControllerType.AC_CONTROLLER
                            );
                          }}
                        >
                          <Row className="align-items-center">
                            <Col xs={"auto"}>
                              <MaterialIcon icon="link" color="#011F5D" />
                            </Col>
                            <Col className="text-light font-size-14 font-weight-500">
                              Link AC Controller
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              )}

              {smartPlugController?.linkedPowerConsumer ||
              smartPlugController?.id ? (
                <Row
                  className={`${styles.socket} align-items-center justify-content-between mt-1 p-2 mx-0`}
                >
                  <Col>
                    <Row className="align-items-center">
                      <Col
                        xs={"auto"}
                        className="text-dark font-size-14 font-weight-500"
                      >
                        {smartPlugController?.name}
                      </Col>
                      <Col className="text-light font-size-12 font-weight-400">
                        {smartPlugController?.serialKey}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={"auto"}>
                    <MaterialIcon
                      icon="link_off"
                      color="#011F5D"
                      className="cursor-pointer"
                      onClick={() => {
                        setUnlinkSmartDeviceType(
                          ESmartControllerType.SMART_PLUG
                        );
                        setDeviceUnlinkModal(true);
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="align-items-center mt-1 g-2">
                  <Col
                    className="cursor-pointer"
                    onClick={() => {
                      setLinkSmartDeviceType(ESmartControllerType.SMART_PLUG);
                      setControllerLinkModal(true);
                    }}
                  >
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <MaterialIcon icon="link" color="#011F5D" />
                      </Col>
                      <Col className="text-light font-size-14 font-weight-500">
                        Link Smart Plug
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          )}
          {acController?.dynamicState?.temperature !== undefined &&
            onIncrease &&
            onDecrease && (
              <Row className="mt-4">
                <Col className="col-auto">
                  <AcController
                    value={acController?.dynamicState.temperature}
                    onIncrease={onIncrease}
                    onDecrease={onDecrease}
                    isShowTemperatureText={true}
                    connectionState={acController.deviceConnectionState}
                  />
                </Col>
              </Row>
            )}
        </Container>
      </AppModal>
      <ControllerLinkModal
        show={controllerLinkModal}
        onCancel={() => setControllerLinkModal(false)}
        onClose={() => setControllerLinkModal(false)}
        device={device}
        setIsPowerConsumerUpdated={props.setIsPowerConsumerUpdated}
        deviceConnectionState={device.deviceConnectionState}
        smartDeviceType={linkSmartDeviceType}
      />
      <DeviceUnlinkModal
        show={deviceUnlinkModal}
        onCancel={() => setDeviceUnlinkModal(false)}
        onClose={() => setDeviceUnlinkModal(false)}
        onConfirm={() => {
          if (unlinkSmartDeviceType === ESmartControllerType.AC_CONTROLLER) {
            unlinkDevice(
              props.spaceClusterId,
              props.spaceId,
              acController?.id || "",
              acController?.linkedPowerConsumer?.id || device.id,
              scheduleIdList
            );
          } else {
            unlinkDevice(
              props.spaceClusterId,
              props.spaceId,
              smartPlugController?.id || "",
              smartPlugController?.linkedPowerConsumer?.id || device.id,
              scheduleIdList
            );
          }
          props.setIsPowerConsumerUpdated &&
            props.setIsPowerConsumerUpdated((ps) => !ps);
          setDeviceUnlinkModal(false);
        }}
        deviceName={device.name}
        controllerName={
          (unlinkSmartDeviceType === ESmartControllerType.AC_CONTROLLER
            ? acController?.name
            : smartPlugController?.name) || ""
        }
        spaceClusterId={props.spaceClusterId}
        linkedPowerConsumerId={
          (unlinkSmartDeviceType === ESmartControllerType.AC_CONTROLLER
            ? acController?.linkedPowerConsumer?.id
            : smartPlugController?.linkedPowerConsumer?.id) || device.id
        }
        setScheduleIdList={setScheduleIdList}
        smartDeviceType={unlinkSmartDeviceType}
        isRegisteredDRP={
          isRegisteredDRP &&
          unlinkSmartDeviceType === ESmartControllerType.SMART_PLUG
        }
        isRemoveDeviceFromDRP={isRemoveDeviceFromDRP}
        setIsRemoveDeviceFromDRP={setIsRemoveDeviceFromDRP}
        smartPlugId={smartPlugController?.id || ""}
      />
    </>
  );
};

export default ViewDeviceModal;
