import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import { selectFilter } from "../../../redux/features/filter/filter-slice";
import AddUpdateSpaceModal from "../../../screens/dashboard/space-clusters/components/models/add-update-space-modal/add-update-space-modal";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { ISpaceView } from "../../oversight-core/interfaces/entities/space";
import { ISelectedSpace } from "../../oversight-core/interfaces/selected-space";
import FilteringSidePanel from "../../oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppSelect, {
  Option,
} from "../../oversight-core/ui-elements/app-select/app-select";
import ButtonWithBadge from "../../oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import IconButton from "../../oversight-core/ui-elements/buttons/icon-button/icon-button";
import Search from "../../oversight-core/ui-elements/search/search";
import SpaceSelectorDropdown from "../../oversight-core/ui-elements/space-selector-dropdown/space-selector-dropdown";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../oversight-core/utils/getDeviceType";
import SelectedFiltersView from "../selected-filters-view/selected-filters-view";

const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true) },
  ...getDeviceTypes(true),
];

const defaultDeviceType: Option = deviceTypes[0];

const groupBy = [
  { label: "Space", value: "Space" },
  { label: "Type", value: "Type" },
];

interface IProps {
  updateFilters: (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    badgeValue: number,
    isClear: boolean
  ) => void;
  isFetching?: boolean;
  updateCurrentState: () => void;
}

const SpaceDeviceFilters = (props: IProps) => {
  const filtersStore = useSelector(selectFilter);
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const { updateFilters } = props;
  const [showFilters, setShowFilters] = useState(false);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    ...filtersStore.selectedFilterSpace,
  });
  const [searchText, setSearchText] = useState(filtersStore.searchText);
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option>({
    ...(deviceTypes.find(
      (deviceType) => deviceType.value === filtersStore.selectedDeviceType
    ) || defaultDeviceType),
  });
  const [selectedGroupBy, setSelectedGroupBy] = useState<Option>({
    ...(groupBy.find(
      (groupByItem) => groupByItem.value === filtersStore.selectedGroupBy
    ) || groupBy[0]),
  });
  const [badgeValue, setBadgeValue] = useState(filtersStore.badgeValue);
  const [showAddUpdateSpaceModal, setShowAddUpdateSpaceModal] = useState(false);

  const [triggerGetSpaces] = useLazyGetSpacesQuery();

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        const tempArray: ISpaceView[] = [];
        for (const spaceCluster of response.spaceClusters) {
          tempArray.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        setSpaceClusters(tempArray);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [
    filtersStore.isSpaceListChanged,
    billingSpaceFilter.isSpaceClusterListChanged,
  ]);

  const handleSearchChange = () => {
    updateFilters(
      {
        clusterId: selectedSpace.clusterId,
        id: selectedSpace.id,
        name: selectedSpace.name,
      },
      searchText,
      selectedDeviceType.value,
      selectedGroupBy.value,
      badgeValue,
      true
    );
  };

  useEffect(() => {
    if (
      selectedSpace.id !== billingSpaceFilter.spaceCluster.rootSpace.id &&
      selectedDeviceType.value &&
      selectedGroupBy.value !== "Space"
    ) {
      setBadgeValue(3);
    } else if (
      (selectedSpace.id !== billingSpaceFilter.spaceCluster.rootSpace.id &&
        selectedDeviceType.value) ||
      (selectedDeviceType.value && selectedGroupBy.value !== "Space") ||
      (selectedSpace.clusterId &&
        selectedSpace.id !== billingSpaceFilter.spaceCluster.rootSpace.id &&
        selectedGroupBy.value !== "Space")
    ) {
      setBadgeValue(2);
    } else if (
      selectedSpace.id !== billingSpaceFilter.spaceCluster.rootSpace.id ||
      selectedDeviceType.value ||
      selectedGroupBy.value !== "Space"
    ) {
      setBadgeValue(1);
    } else {
      setBadgeValue(0);
    }
  }, [
    selectedSpace.clusterId,
    selectedSpace.id,
    selectedDeviceType.value,
    selectedGroupBy.value,
  ]);

  useEffect(() => {
    setSelectedSpace(filtersStore.selectedFilterSpace);
  }, [filtersStore.selectedFilterSpace]);

  return (
    <>
      <Row className="align-items-center justify-content-center justify-content-sm-between">
        <Col
          xs={{ order: 2 }}
          sm={{ order: 1 }}
          className="col-12 col-lg-7 col-xl-6 col-xxl-5 mt-3 mt-sm-0 pe-4 pe-sm-0"
        >
          <Row className="align-items-center">
            <Col className="col-12 col-sm-6">
              <Search
                onSearch={handleSearchChange}
                handleSearchChange={(searchText) => {
                  setSearchText(searchText);
                }}
                value={searchText}
                placeholder="Search by Device Name"
              />
            </Col>
            <Col className="col-12 col-sm-6 mt-2 mt-sm-0">
              {!filtersStore.selectedDeviceType &&
                !filtersStore.searchText &&
                filtersStore.selectedGroupBy === `Space` && (
                  <IconButton
                    icon="add"
                    text="Create Billing Space"
                    onClick={() => setShowAddUpdateSpaceModal(true)}
                    variant="transparentWithBorder"
                  />
                )}
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ order: 1 }}
          sm={{ order: 2 }}
          className="col-12 col-sm-auto mt-2 mt-lg-0"
        >
          <Row className="justify-content-center justify-content-sm-end align-items-center px-1">
            <Col className="col-auto">
              <SelectedFiltersView
                spaceName={filtersStore.selectedSpace.name}
                filters={[
                  {
                    icon: filtersStore.selectedDeviceType
                      ? (filtersStore.selectedDeviceType as EDeviceTypes)
                      : EDeviceTypes.ALL_DEVICES,
                    filterName:
                      filtersStore.selectedDeviceType || "All Devices",
                  },
                ]}
              />
            </Col>
            <Col className="col-auto">
              <ButtonWithBadge
                text="Filter"
                icon="filter_alt"
                onClick={() => {
                  setShowFilters(true);
                }}
                badgeValue={badgeValue}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <FilteringSidePanel
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        onFilter={() => {
          setShowFilters(false);
          updateFilters(
            {
              clusterId: selectedSpace.clusterId,
              id: selectedSpace.id,
              name: selectedSpace.name,
            },
            searchText,
            selectedDeviceType.value,
            selectedGroupBy.value,
            badgeValue,
            true
          );
        }}
        onClearAll={() => {
          if (
            filtersStore.selectedFilterSpace.id !==
              billingSpaceFilter.spaceCluster.rootSpace.id ||
            filtersStore.isClear
          ) {
            updateFilters(
              {
                clusterId: billingSpaceFilter.spaceCluster.id,
                id: billingSpaceFilter.spaceCluster.rootSpace.id,
                name: billingSpaceFilter.spaceCluster.label,
              },
              "",
              "",
              "Space",
              0,
              false
            );
            setSelectedSpace({ ...filtersStore.selectedSpace });
            setSelectedDeviceType({ ...defaultDeviceType });
            setSelectedGroupBy({ ...groupBy[0] });
            setBadgeValue(0);
          }
          setShowFilters(false);
        }}
      >
        <>
          <SpaceSelectorDropdown
            dropdownOptions={[
              ...spaceClusters
                .filter(
                  (space) =>
                    space.clusterId ===
                    filtersStore.selectedFilterSpace.clusterId
                )
                .map((space) => {
                  return {
                    clusterId: space.clusterId,
                    id: space.id,
                    name: space.name,
                    childSpaces: space.childSpaces,
                  };
                }),
            ]}
            disabled={false}
            selectedSubSpace={selectedSpace.name}
            updateSelectedSubSpaceId={(
              selectedSubSpaceId: string,
              selectedSubSpaceName: string,
              clusterId?: string
            ) => {
              setSelectedSpace({
                id: selectedSubSpaceId,
                name: selectedSubSpaceName,
                clusterId: clusterId || "",
              });
            }}
            label="Space"
            className="font-weight-500 text-light"
          />
          <AppSelect
            label="Device Type"
            options={[...deviceTypes]}
            className="mt-4"
            selectedValue={selectedDeviceType}
            onChangeOption={(selectedDeviceTypeOption) =>
              setSelectedDeviceType(selectedDeviceTypeOption)
            }
            disabled={selectedGroupBy.value === "Type"}
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />
          <AppSelect
            label="Group By"
            options={groupBy}
            className="mt-4"
            selectedValue={selectedGroupBy}
            onChangeOption={(selectedGroupByOption) => {
              setSelectedGroupBy(selectedGroupByOption);
            }}
            disabled={selectedDeviceType.value ? true : false}
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />
        </>
      </FilteringSidePanel>
      <AddUpdateSpaceModal
        show={showAddUpdateSpaceModal}
        spaceCreationType={"spaceCluster"}
        updateCurrentState={props.updateCurrentState}
        onClose={() => setShowAddUpdateSpaceModal(false)}
        onCancel={() => setShowAddUpdateSpaceModal(false)}
        spaceClusterId={""}
        parentSpaceId={""}
      />
    </>
  );
};

export default SpaceDeviceFilters;
