import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import {
  useAddDeviceMutation,
  useEditDeviceMutation,
} from "../../../../../../redux/api/device/deviceAPI";
import { EAcProtocolType } from "../../../../../../shared/oversight-core/enums/ac-protocol-type";
import { EDeviceTypes } from "../../../../../../shared/oversight-core/enums/device-types";
import { IPowerConsumerView } from "../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import ConfirmationModal from "../../../../../../shared/oversight-core/shared-components/confirmation-modal/confirmation-modal";
import InfoModal from "../../../../../../shared/oversight-core/shared-components/info-modal/info-modal";
import AppSelect, {
  Option,
} from "../../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppInput from "../../../../../../shared/oversight-core/ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import getACProtocolType from "../../../../../../shared/oversight-core/utils/getAcProtocolType";
import getDeviceTypes from "../../../../../../shared/oversight-core/utils/getDeviceType";
import {
  decimalRegex,
  noSpecialCharsNoWhitespaceStartEndAllowHyphenRegex,
  noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
  noSpecialCharsNoWhitespaceStartEndRegex,
} from "../../../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";

interface IFormInput {
  name: string;
  deviceType: Option | [];
  deviceCategory: string;
  brand: string;
  modelNumber: string;
  serialKey: string | null;
  powerUsageInWatt: number | undefined;
  protocol: Option | [];
}

const defaultFormValues: IFormInput = {
  name: "",
  deviceType: [],
  deviceCategory: "",
  brand: "",
  modelNumber: "",
  serialKey: "",
  powerUsageInWatt: undefined,
  protocol: [],
};

interface IProps extends ModelContainerProps {
  spaceId: string;
  spaceClusterId: string;
  updateCurrentState: () => void;
  device?: IPowerConsumerView;
  setIsPowerConsumerUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUpdateDeviceModal = (props: IProps) => {
  const { spaceClusterId, spaceId, ...rest } = props;
  const [fetchPowerConsumer, setFetchPowerConsumer] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const [
    addDevice,
    { isSuccess: isSuccessAddDevice, isLoading: isLoadingAddDevice },
  ] = useAddDeviceMutation();
  const [
    editDevice,
    { isSuccess: isSuccessEditDevice, isLoading: isLoadingEditDevice },
  ] = useEditDeviceMutation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const currentDeviceType = watch("deviceType") as Option;
  const currentProtocol = watch("protocol") as Option;

  useEffect(() => {
    props.setIsPowerConsumerUpdated &&
      props.setIsPowerConsumerUpdated(fetchPowerConsumer);
  }, [fetchPowerConsumer]);

  useEffect(() => {
    if (props.device) {
      const device = props.device;
      setValue("name", device.name);
      setValue(
        "deviceType",
        getDeviceTypes().find((d) => d.value === device.deviceType) || []
      );
      setValue(
        "protocol",
        getACProtocolType().find((d) => d.value === device.protocol) || []
      );
      setValue("deviceCategory", device.deviceCategory);
      setValue("brand", device.brand);
      setValue("modelNumber", device.modelNumber);
      setValue("serialKey", device.serialKey);
      setValue("powerUsageInWatt", device.powerUsageInWatt || 0);
    } else {
      setValue("powerUsageInWatt", undefined);
      reset({ ...defaultFormValues });
    }
  }, [props.show]);

  const onSubmit = (data: IFormInput) => {
    const protocol = (data.protocol as Option).value as EAcProtocolType;
    const modelNumber = data.modelNumber;
    const deviceType = (data.deviceType as Option).value as EDeviceTypes;

    if (!props.device) {
      addDevice({
        spaceClusterId,
        spaceId,
        registeringDevices: [
          {
            ...data,
            deviceType,
            powerUsageInWatt: data.powerUsageInWatt ?? 0,
            serialKey: deviceType,
            deviceCategory: deviceType,
            modelNumber,
            protocol,
          },
        ],
      })
        .unwrap()
        .then(() => {
          ReactGA.event({
            category: "Successful",
            action: "Add Device",
          });
        })
        .catch((error) => {
          if (error.ovstErrorCode === "OVST_CONS_0015") {
            showErrorMessage("Device name Already Exists");
          } else {
            showErrorMessage("Adding Device Unsuccessful");
          }
        });
    } else {
      if (props.device) {
        setShowConfirmationModal(true);
        return;
      }
    }
  };

  useEffect(() => {
    if (isSuccessAddDevice || isSuccessEditDevice) {
      const message = isSuccessAddDevice
        ? "Device Added Successfully"
        : "Device Updated Successfully";
      showSuccessMessage(message);
      setFetchPowerConsumer(true);
      props.updateCurrentState();
      props.onClose && props.onClose();
      reset({ ...defaultFormValues });
    }
  }, [isSuccessAddDevice, isSuccessEditDevice]);

  const onConfirmUpdateDevice = (data: IFormInput) => {
    const deviceType = (data.deviceType as Option).value as EDeviceTypes;
    const protocol = (data.protocol as Option).value as EAcProtocolType;
    const modelNumber = data.modelNumber;

    if (props.device) {
      editDevice({
        spaceClusterId,
        spaceId,
        registeringDevice: {
          ...data,
          deviceType,
          deviceCategory: deviceType,
          serialKey: deviceType,
          id: props.device.id,
          powerUsageInWatt: data.powerUsageInWatt ?? 0,
          modelNumber,
          protocol,
        },
      })
        .unwrap()
        .then(() => {
          setShowConfirmationModal(false);
        })
        .catch((error) => {
          if (error.ovstErrorCode === "OVST_CONS_0015") {
            showErrorMessage("Device name Already Exists");
          } else if (error.ovstErrorCode === "OVST_CONS_0001") {
            setShowInfoModal(true);
          } else {
            showErrorMessage("Editing Unsuccessful");
          }
        });
    }
  };

  return (
    <>
      <ModalContainer
        {...rest}
        title={props.device ? "Edit Device" : "Add New Device"}
        size="modal-lg"
        onConfirm={handleSubmit(onSubmit)}
        isLoading={isLoadingAddDevice}
        confirmId="add-device-confirm"
      >
        <>
          <AppInput
            label="Device Name"
            placeholder="Device Name"
            name="name"
            register={register("name", {
              required: "Device name is required",
              maxLength: {
                value: 20,
                message:
                  "You have exceeded the maximum number of 20 characters in this field",
              },
              pattern: {
                value: noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
                message:
                  "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
              },
            })}
            errors={errors}
          />
          <AppSelect
            className="mt-4"
            defaultValue={""}
            placeholder="Device Type"
            options={getDeviceTypes()}
            label="Device Type"
            control={control}
            name="deviceType"
            errors={errors}
            register={register("deviceType", {
              required: "Please select the device type",
            })}
            isRequired={true}
            id="device-type"
          />
          <AppInput
            className="mt-4"
            label="Brand"
            placeholder="Brand"
            name="brand"
            register={register("brand", {
              required: "Brand is required",
              pattern: {
                value: noSpecialCharsNoWhitespaceStartEndRegex,
                message:
                  "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
              },
            })}
            errors={errors}
          />
          <AppInput
            className="mt-4"
            label="Model Number"
            placeholder="Model Number"
            name="modelNumber"
            register={register("modelNumber", {
              required: "Model number is required",
              pattern: {
                value: noSpecialCharsNoWhitespaceStartEndAllowHyphenRegex,
                message:
                  "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
              },
            })}
            errors={errors}
          />
          {currentDeviceType.value === EDeviceTypes.AIR_CONDITIONER && (
            <AppSelect
              className="mt-4"
              defaultValue={""}
              menuHeight="200px"
              placeholder="Select model"
              options={getACProtocolType()}
              label="Communication Protocol"
              control={control}
              name="protocol"
              errors={errors}
              register={register("protocol", {
                required: "Model is required",
              })}
              isRequired={true}
              id="protocol"
            />
          )}
          {currentProtocol.value === EAcProtocolType.OTHER && (
            <p className="text-blue font-weight-500 font-size-12">
              Devices with Other types of communication protocols won&apos;t be
              able to link to an A/C Controller.
            </p>
          )}
          <AppInput
            className="mt-4"
            type="number"
            label="Average Energy Usage (W)"
            placeholder="Average Energy Usage (W)"
            name="powerUsageInWatt"
            register={register("powerUsageInWatt", {
              required: "Average energy usage is required",
              min: {
                value: 0.0001,
                message: "Please enter a value greater than 0",
              },
              pattern: {
                value: decimalRegex,
                message: "Please enter only upto 2 decimals",
              },
            })}
            errors={errors}
          />
        </>
      </ModalContainer>
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={handleSubmit(onConfirmUpdateDevice)}
        title="Update Device"
        isLoading={isLoadingEditDevice}
      />
      <InfoModal
        show={showInfoModal}
        message="Device is already connected to a AC controller with supported device protocol and the new device protocol is not supported by the existing ac controller. Please unlink the ac controller first before updating device."
        hideCancel={true}
        onConfirm={() => {
          setShowInfoModal(false);
          setShowConfirmationModal(false);
        }}
        onClose={() => {
          setShowInfoModal(false);
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};

export default AddUpdateDeviceModal;
