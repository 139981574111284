import { useState } from "react";
import Avatar from "react-avatar";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setPrivileges,
  setToken,
} from "../../../redux/features/auth/auth-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import Logo from "../../../shared/oversight-core/assets/images/breezeLink-logo.svg";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import LogoutModal from "../../../shared/oversight-core/shared-components/logout-modal/logout-modal";
import UserProfileDataEdit from "../../../shared/oversight-core/shared-pages/user-profile/components/user-profile-data-edit/user-profile-data-edit";
import UserProfileData from "../../../shared/oversight-core/shared-pages/user-profile/components/user-profile-data/user-profile-data";
import AppDropDown from "../../../shared/oversight-core/ui-elements/app-dropdown/app-drop-down";
import ArrowButton from "../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./breezeLink-temp-user-profile.module.scss";

const BreezeLinkTempUserProfile = () => {
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showLogout, setShowLogout] = useState(false);

  const logOutHandler = () => {
    localStorage.clear();
    dispatch(setToken(""));
    dispatch(setPrivileges([]));
    navigate(AppRoute.ACCESS_BREEZE_LINK);
  };

  return (
    <div className={styles.container}>
      <Container fluid className="py-4 px-4 px-sm-5">
        <Row className="align-items-center justify-content-between">
          <Col className="order-2 order-sm-1 col-auto col-sm">
            <img
              src={Logo}
              alt="BreezeLink Logo"
              onClick={() => navigate(AppRoute.TEMP_USER_DASHBOARD)}
            />
          </Col>
          <Col className="col-auto order-1 order-sm-2">
            <Row className="align-items-center">
              <Col className="col-auto pe-3 order-2 order-sm-1">
                <MaterialIcon
                  icon="notifications"
                  size={25}
                  className="cursor-pointer"
                />
              </Col>
              <Col className="col-auto px-0 order-1 order-sm-2">
                <Avatar
                  name={`${profile?.firstName} ${profile?.lastName}`}
                  size="40"
                  round={true}
                  className="cursor-pointer"
                />
              </Col>
              <Col className="text-dark font-weight-500 font-size-14 d-none d-sm-block order-3">
                {profile?.firstName}
                <br /> {profile?.lastName}
              </Col>
              <Col className="col-auto order-4">
                <AppDropDown
                  items={[
                    {
                      text: "Profile",
                      onClick: () => {
                        navigate(AppRoute.TEMP_USER_PROFILE);
                      },
                    },
                    {
                      text: "Log Out",
                      onClick: () => setShowLogout(true),
                    },
                  ]}
                  icon="expand_more"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-4 mx-0">
          <Col>
            <ArrowButton onBack={() => navigate(-1)} />
          </Col>
        </Row>
        {profile && (
          <Row className="mt-5">
            <Col xl={6}>
              <Row
                className={`${styles.userProfile} h-100 align-items-center justify-content-center gap-3 p-4 mx-0`}
              >
                <Col className="col-auto me-2">
                  <Avatar
                    name={`${profile.firstName} ${profile.lastName}`}
                    size="150"
                    round={true}
                  />
                </Col>
                <Col className="col-md-7  col-12">
                  <UserProfileData
                    firstName={profile.firstName}
                    lastName={profile.lastName}
                    NIC={"-"}
                    username={profile.username}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6}>
              <Row className="mt-4 mt-xl-0">
                <Col>
                  {profile && (
                    <UserProfileDataEdit
                      title="Mobile Number"
                      text={profile.mobileNumber}
                      showEditIcon={false}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {profile && (
                    <UserProfileDataEdit
                      title="Email"
                      text={profile.email}
                      showEditIcon={false}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {profile && (
                    <UserProfileDataEdit
                      title="Password"
                      text="* * * * * * * * *"
                      showEditIcon={false}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
      <LogoutModal
        show={showLogout}
        onCancel={() => setShowLogout(false)}
        onClose={() => setShowLogout(false)}
        onConfirm={() => {
          setShowLogout(false);
          logOutHandler();
        }}
      />
    </div>
  );
};

export default BreezeLinkTempUserProfile;
