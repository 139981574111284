import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AcSchedule from "../../../../shared/components/ac-schedule/ac-schedule";
import { EDeviceTypes } from "../../../../shared/oversight-core/enums/device-types";
import ActiveInactiveIndicator from "../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import IconButton from "../../../../shared/oversight-core/ui-elements/buttons/icon-button/icon-button";
import DaysOfWeek from "../../../../shared/oversight-core/ui-elements/days-of-week/days-of-week";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import findIcon from "../../../../shared/oversight-core/utils/findIcon";

interface IProps extends ModelContainerProps {
  show: boolean;
}

interface ISchedule {
  id: number;
  time: string;
  isOn: boolean;
  temperature: number;
}

const initialSchedule = {
  id: 0,
  time: "8:30 AM",
  isOn: true,
  temperature: 24,
};

const ScheduleUsageModal = (props: IProps) => {
  const { show, ...rest } = props;

  const selectedDays = [
    new Date(2024, 10, 4),
    new Date(2024, 10, 5),
    new Date(2024, 10, 6),
  ];
  const disabledDays = [new Date(2024, 10, 8), new Date(2024, 10, 9)];

  const [schedules, setSchedules] = useState<ISchedule[]>([
    { ...initialSchedule },
  ]);
  const [error, setError] = useState<string>("");

  const parseTime = (time: string) => {
    const [hours, minutes] = time.split(":");
    const isAM = time.includes("AM");
    let adjustedHours = parseInt(hours);
    if (!isAM && adjustedHours !== 12) adjustedHours += 12;
    if (isAM && adjustedHours === 12) adjustedHours = 0;
    return new Date(2000, 0, 1, adjustedHours, parseInt(minutes.split(" ")[0]));
  };

  useEffect(() => {
    if (show) {
      setSchedules([{ ...initialSchedule }]);
      setError("");
    }
  }, [show]);

  const handleAddSchedule = () => {
    if (schedules.length >= 2) {
      const lastSchedule = schedules[schedules.length - 1];
      const secondLastSchedule = schedules[schedules.length - 2];

      const lastTime = parseTime(lastSchedule.time);
      const secondLastTime = parseTime(secondLastSchedule.time);

      if (lastTime <= secondLastTime) {
        setError("The schedule times not correct");
        return;
      }
      setError("");
    }

    setSchedules((prevSchedules) => {
      const lastId = prevSchedules.length
        ? prevSchedules[prevSchedules.length - 1].id
        : 0;
      return [...prevSchedules, { ...initialSchedule, id: lastId + 1 }];
    });
  };

  const handleRemoveSchedule = (id: number) => {
    setSchedules((prevSchedules) =>
      prevSchedules
        .filter((schedule) => schedule.id !== id)
        .map((schedule, index) => ({ ...schedule, id: index + 1 }))
    );
    setError("");
  };

  const handleTimeChange = (id: number, time: string) => {
    setSchedules(
      schedules.map((schedule) =>
        schedule.id === id ? { ...schedule, time } : schedule
      )
    );
  };

  const handleToggleChange = (id: number, isOn: boolean) => {
    setSchedules(
      schedules.map((schedule) =>
        schedule.id === id ? { ...schedule, isOn } : schedule
      )
    );
  };

  const handleTemperatureChange = (id: number, temperature: number) => {
    setSchedules(
      schedules.map((schedule) =>
        schedule.id === id ? { ...schedule, temperature } : schedule
      )
    );
  };

  return (
    <ModalContainer
      {...rest}
      title="Schedule Usage"
      show={show}
      confirmButtonText="Save"
      cancelButtonText="Cancel"
    >
      <>
        <Row className="align-items-center mx-0">
          <Col
            className={`${EDeviceTypes.AIR_CONDITIONER} p-3 rounded col-auto`}
          >
            <MaterialIcon icon={findIcon(EDeviceTypes.AIR_CONDITIONER)} />
          </Col>
          <Col>
            <Row>
              <Col className="text-dark font-weight-500 font-size-20">
                Room 1 AC
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="col-auto pe-0">
                <ActiveInactiveIndicator isActive />
              </Col>
              <Col className="col-auto text-light font-weight-400 font-size-14 ps-2">
                450 W
              </Col>
              <Col className="col-auto text-light font-weight-400 font-size-14 ps-1">
                Room 1
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-dark font-weight-400 font-size-16">
            Repeat&nbsp;&nbsp;
            <span className="text-light font-weight-400 font-size-14">
              24 March 02:00 PM - 1 April 11:30 AM
            </span>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <DaysOfWeek
              selectedDays={selectedDays}
              disabledDays={disabledDays}
            />
          </Col>
        </Row>
        <div className="mt-5">
          {schedules.map((schedule) => (
            <Row key={schedule.id} className="mt-3">
              <Col>
                <AcSchedule
                  schedule={schedule}
                  onRemove={() => handleRemoveSchedule(schedule.id)}
                  onTimeChange={(time) =>
                    handleTimeChange(schedule.id, time.value)
                  }
                  onToggleChange={(isOn) =>
                    handleToggleChange(schedule.id, isOn)
                  }
                  onTemperatureChange={(temperature) =>
                    handleTemperatureChange(schedule.id, temperature)
                  }
                  scheduleCounter={schedules.length}
                />
              </Col>
            </Row>
          ))}
          {error.length > 0 && (
            <Row className="mt-1">
              <Col className="text-danger font-size-14 font-weight-400">
                {error}
              </Col>
            </Row>
          )}
        </div>
        <Row className="mt-4">
          <Col className="col-auto">
            <IconButton
              icon="add"
              text="Add Time Slot"
              onClick={handleAddSchedule}
            />
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default ScheduleUsageModal;
