import { useCallback, useEffect, useRef, useState } from "react";
import { Badge } from "react-bootstrap";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";
import styles from "./app-drop-down.module.scss";

interface MenuItem {
  id?: string;
  text: string;
  onClick?: () => void;
  label?: string;
}

interface IProps {
  items: MenuItem[];
  buttonSize?: number;
  onItemClick?: (item: MenuItem) => void;
  icon?: MtIcon;
  id?: string;
}

const AppDropDown = (props: IProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState<{
    x: "menu-left" | "menu-right";
    y: "menu-top" | "menu-bottom";
  }>({ x: "menu-left", y: "menu-top" });
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      menuButtonRef.current &&
      !menuButtonRef.current.contains(e.target as Node)
    ) {
      setShowMenu(false);
    }
  };

  const calcMenuPosition = useCallback(() => {
    const hasWindow = typeof window !== "undefined";
    const docW = hasWindow ? window.innerWidth : 0;
    const docH = hasWindow ? window.innerHeight : 0;
    if (menuRef.current) {
      const style = menuRef.current.style;
      style.visibility = "hidden";
      style.display = "block";
      const bRect = menuRef.current.getBoundingClientRect();
      style.display = "none";
      style.visibility = "visible";
      setMenuPosition((ps) => {
        if (0 >= bRect.top) ps.y = "menu-top";
        if (0 >= bRect.left) ps.x = "menu-left";
        if (docW <= bRect.right) ps.x = "menu-right";
        if (docH <= bRect.bottom) ps.y = "menu-bottom";
        return { ...ps };
      });
    }
  }, []);

  const onMenuShow = () => {
    calcMenuPosition();
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    calcMenuPosition();
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calcMenuPosition]);

  return (
    <div
      className={`${styles["drop-down-container"]} d-flex`}
      ref={menuButtonRef}
    >
      <div
        className={`${styles["drop-down-icon"]} ${
          props.icon === "more_horiz" && `px-3 ${styles.moreHorBorderRadius}`
        }`}
        onClick={(e) => {
          e.stopPropagation();
          onMenuShow();
        }}
        id={props.id}
        style={{ width: `${props.icon === "expand_more" && `26px`}` }}
      >
        <MaterialIcon
          icon={props.icon ? `${props.icon}` : `more_vert`}
          color="#B9B6CB"
          className="cursor-pointer"
          size={props.buttonSize || 18}
        />
      </div>
      <div
        className={`${styles["menu-container"]} ${
          showMenu ? styles["show-menu"] : styles["hide-menu"]
        } ${styles[menuPosition.y]} ${styles[menuPosition.x]}`}
        ref={menuRef}
      >
        <ul>
          {props.items.map((item, index) => (
            <li
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu(false);
                if (props.onItemClick) props.onItemClick(item);
                if (item.onClick) item.onClick();
              }}
            >
              <div className="d-flex align-items-center">
                {item.text}
                {item.label && (
                  <Badge className={`${styles.badge} ms-1`} bg="#">
                    {item.label}
                  </Badge>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AppDropDown;
