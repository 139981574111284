import { Col, Row } from "react-bootstrap";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";

interface IProps {
  value: number;
  maxValue: number;
  icon?: MtIcon;
  pathColor: string;
  trailColor: string;
  unit?: string;
  width?: number;
  height?: number;
  fontSizeValue?: number;
  fontSizeLabel?: number;
}

const Gauge = (props: IProps) => {
  const {
    icon,
    value,
    maxValue,
    pathColor,
    trailColor,
    unit,
    width,
    height,
    fontSizeValue = 32,
    fontSizeLabel = 16,
  } = props;

  const calculatePercentage = (currentValue: number, maxValue: number) => {
    if (maxValue === 0) return 0;
    return (currentValue / maxValue) * 100;
  };

  return (
    <div style={{ width: width ? width : 200, height: height ? height : 100 }}>
      <CircularProgressbarWithChildren
        value={calculatePercentage(value, maxValue)}
        styles={buildStyles({
          rotation: 0.75, // Rotate the circle to make it a half-circle
          pathColor: pathColor, // Color for the gauge
          trailColor: trailColor, // Background trail color
          pathTransitionDuration: 0.5,
        })}
        circleRatio={0.5} // Makes it a half-circle
      >
        <Row className={"align-items-center justify-content-center mb-4"}>
          {icon && (
            <Col xs={"auto"} className="pe-0">
              <MaterialIcon icon={icon} size={25} color="#D08700" />
            </Col>
          )}
          <Col xs={"auto"} className="ps-1">
            <label
              className={`text-dark font-size-${fontSizeValue} font-weight-500`}
            >
              {value}
            </label>
            <label
              className={`text-light font-size-${fontSizeLabel} font-weight-40 ps-1`}
            >
              {unit ? unit : <>&deg;C</>}
            </label>
          </Col>
        </Row>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default Gauge;
