import LoginLayout from "../../../shared/oversight-core/shared-components/login-layout/login-layout";
import AccessBreezeLinkForm from "./access-breezeLink-form/access-breezeLink-form";

const AccessBreezeLink = () => {
  return (
    <LoginLayout title="">
      <AccessBreezeLinkForm />
    </LoginLayout>
  );
};

export default AccessBreezeLink;
