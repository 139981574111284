import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddSpaceClusterMutation,
  useAddSpaceMutation,
  useEditSpaceClusterMutation,
  useEditSpaceMutation,
} from "../../../../../../redux/api/space/spaceAPI";
import { useLazyViewUnAssignedAccountNumberQuery } from "../../../../../../redux/api/user/userAPI";
import {
  setIsSpaceClusterListChanged,
  setNewClusterId,
} from "../../../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  selectFilter,
  setIsSpaceListChanged,
  setSelectedSpace,
} from "../../../../../../redux/features/filter/filter-slice";
import { selectProfile } from "../../../../../../redux/features/user/user-slice";
import AddSpaceClusterDTO from "../../../../../../shared/oversight-core/dtos/request-dtos/add-space-cluster-dto";
import { OvstErrorCode } from "../../../../../../shared/oversight-core/enums/ovst-error-codes";
import { EProviders } from "../../../../../../shared/oversight-core/enums/providers";
import { ISpaceView } from "../../../../../../shared/oversight-core/interfaces/entities/space";
import ConfirmationModal from "../../../../../../shared/oversight-core/shared-components/confirmation-modal/confirmation-modal";
import AddUpdateAccountNumberModal from "../../../../../../shared/oversight-core/shared-pages/user-profile/components/modals/add-update-account-number-modal/add-update-account-number-modal";
import AppSelect, {
  Option,
} from "../../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import AppInput from "../../../../../../shared/oversight-core/ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import { noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex } from "../../../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";
//import AppSwitch from "../../../../../../shared/components/switch/app-switch";

interface IProps extends ModelContainerProps {
  parentSpaceId: string;
  spaceClusterId: string;
  spaceCreationType: "space" | "spaceCluster";
  updateCurrentState: () => void;
  spaceClusterDetails?: ISpaceView;
  isEditMode?: boolean;
}

const AddUpdateSpaceModal = (props: IProps) => {
  const { ...rest } = props;
  const [addSpace, { isLoading: isLoadingAddSpace }] = useAddSpaceMutation();
  const [addSpaceCluster, { isLoading: isLoadingAddSpaceCluster }] =
    useAddSpaceClusterMutation();
  const [editSpaceCluster, { isLoading: isLoadingEditSpaceCluster }] =
    useEditSpaceClusterMutation();
  const [editSpace, { isLoading: isLoadingEditSpace }] = useEditSpaceMutation();
  const [accountNumbers, setAccountNumbers] = useState<Option[]>([]);
  const [showAddUpdateAccountNumberModal, setShowAddUpdateAccountNumberModal] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const filtersStore = useSelector(selectFilter);
  //const [checked, setChecked] = useState(false);
  // const handleClick = () => {
  //   setChecked(!checked);
  // };
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);

  const [
    triggerViewUnAssignedAccountNumber,
    { isFetching: isFetchingUnAssignedAccountNumber },
  ] = useLazyViewUnAssignedAccountNumberQuery();

  interface IFormInput
    extends Omit<AddSpaceClusterDTO, "tariffCode" | "accountNumber"> {
    accountNumber: Option | Option[] | undefined | [];
  }

  const defaultFormValues: IFormInput = {
    spaceClusterLabel: "",
    rootSpaceName: "",
    accountNumber: [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm<IFormInput>({
    defaultValues: {
      ...defaultFormValues,
    },
  });

  const getAccountNumbers = () => {
    triggerViewUnAssignedAccountNumber()
      .unwrap()
      .then((response) => {
        if (!response.accountNumbers) {
          return;
        }

        const tempAccountNumbers: Option[] = Array.isArray(
          response.accountNumbers
        )
          ? []
          : Object.entries(response.accountNumbers).map(([key, value]) => {
              return { label: `${value} - ${key}`, value: key };
            });

        if (props.spaceClusterDetails) {
          tempAccountNumbers.unshift({
            label: `${props.spaceClusterDetails.accountNumberLabel} - ${props.spaceClusterDetails.accountNumber}`,
            value: props.spaceClusterDetails.accountNumber,
          });
        }
        setAccountNumbers(tempAccountNumbers);

        if (props.isEditMode) {
          setValue("spaceClusterLabel", props.spaceClusterDetails?.name || "");
          setValue(
            "accountNumber",
            tempAccountNumbers.find(
              (option) =>
                option.value === props.spaceClusterDetails?.accountNumber
            ) || []
          );
        }
      });
  };

  useEffect(() => {
    if (props.show) {
      reset({ ...defaultFormValues });
      getAccountNumbers();
    }
  }, [props.show]);

  const requestSuccessHandler = (message: string) => {
    showSuccessMessage(message);
    props.updateCurrentState();
    props.onClose && props.onClose();
    reset({ ...defaultFormValues });
  };

  const onSubmit = (data: IFormInput) => {
    if (!props.isEditMode) {
      if (props.spaceCreationType === "spaceCluster") {
        addSpaceCluster({
          spaceClusterLabel: data.spaceClusterLabel,
          rootSpaceName: data.spaceClusterLabel,
          accountNumber: (data.accountNumber as Option).value,
        })
          .unwrap()
          .then((res) => {
            requestSuccessHandler("Billing Space Added Successfully");
            dispatch(setNewClusterId(res.spaceClusterId));
          })
          .catch((error) => {
            if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0007) {
              showErrorMessage("Space name Already Exists");
            } else {
              showErrorMessage("Adding Billing Space Unsuccessful");
            }
          });
      } else {
        addSpace({
          spaceClusterId: props.spaceClusterId,
          parentSpaceId: props.parentSpaceId,
          spaceName: data.spaceClusterLabel,
        })
          .unwrap()
          .then(() => {
            requestSuccessHandler("Space Added Successfully");
            dispatch(setIsSpaceListChanged());
          })
          .catch((error) => {
            if (error.status === 404) {
              showErrorMessage("");
            } else if (error.status === 412) {
              if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0007) {
                showErrorMessage("Space name Already Exists");
              } else if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0006) {
                showErrorMessage(
                  "Space cluster has reached its depth level for the space"
                );
              } else {
                showErrorMessage("Adding Space Unsuccessful");
              }
            }
          });
      }
    } else if (props.isEditMode) {
      if (props.spaceCreationType === "spaceCluster") {
        if (
          props.spaceClusterDetails?.accountNumber !==
          (data.accountNumber as Option).value
        ) {
          setShowConfirmationModal(true);
          return;
        }
        setIsLoading(true);
        editSpaceCluster({
          spaceClusterId: props.spaceClusterId,
          label: data.spaceClusterLabel,
          accountNumber: (data.accountNumber as Option).value,
        })
          .unwrap()
          .then(() => {
            requestSuccessHandler("Billing Space Updated Successfully");
            dispatch(setIsSpaceClusterListChanged());
          })
          .catch((error) => {
            if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0007) {
              showErrorMessage("Space name Already Exists");
            } else {
              showErrorMessage("Editing Billing Space Unsuccessful");
            }
          });
      } else {
        editSpace({
          spaceClusterId: props.spaceClusterId,
          spaceId: props.parentSpaceId,
          spaceName: data.spaceClusterLabel,
        })
          .unwrap()
          .then(() => {
            requestSuccessHandler("Space Updated Successfully");
            dispatch(setIsSpaceListChanged());

            if (
              filtersStore.selectedSpace.clusterId === props.spaceClusterId &&
              filtersStore.selectedSpace.id === props.parentSpaceId
            ) {
              dispatch(
                setSelectedSpace({
                  id: props.parentSpaceId,
                  clusterId: props.spaceClusterId,
                  name: data.spaceClusterLabel,
                })
              );
            }
          })
          .catch((error) => {
            if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0007) {
              showErrorMessage("Space name Already Exists");
            } else {
              showErrorMessage("Editing Space Unsuccessful");
            }
          });
      }
    }
  };

  const onConfirmUpdateBillingSpaceCluster = (data: IFormInput) => {
    editSpaceCluster({
      spaceClusterId: props.spaceClusterId,
      label: data.spaceClusterLabel,
      accountNumber: (data.accountNumber as Option).value,
    })
      .unwrap()
      .then(() => {
        requestSuccessHandler("Billing Space Updated Successfully");
        setShowConfirmationModal(false);
        dispatch(setIsSpaceClusterListChanged());
      })
      .catch((error) => {
        if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0007) {
          showErrorMessage("Space name Already Exists");
        } else {
          showErrorMessage("Editing Billing Space Unsuccessful");
        }
      });
  };

  return (
    <>
      <ModalContainer
        {...rest}
        title={props.isEditMode ? `Edit Space` : `Add New Space`}
        onConfirm={handleSubmit(onSubmit)}
        isLoading={
          isLoadingAddSpace ||
          isLoadingAddSpaceCluster ||
          isLoadingEditSpace ||
          isFetchingUnAssignedAccountNumber ||
          (isLoadingEditSpaceCluster && isLoading)
        }
        confirmId="add-space-confirm"
      >
        <>
          <AppInput
            label="Space Name"
            name="spaceClusterLabel"
            placeholder="Space Name"
            register={register("spaceClusterLabel", {
              required: "Space name is required",
              maxLength: {
                value: 20,
                message:
                  "You have exceeded the maximum number of 20 characters in this field ",
              },
              pattern: {
                value: noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
                message:
                  "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
              },
            })}
            errors={errors}
          />
          {props.spaceCreationType === "spaceCluster" && (
            <Row
              className={`align-items-${
                errors && errors.accountNumber?.message ? `center` : `end`
              } mt-4`}
            >
              <Col>
                <AppSelect
                  defaultValue={""}
                  label="Billing Account Number"
                  options={accountNumbers}
                  control={control}
                  name="accountNumber"
                  placeholder="Billing Account Number"
                  register={register("accountNumber", {
                    required: "Please select an account number",
                  })}
                  errors={errors}
                  menuHeight="120px"
                  isRequired={true}
                  id="billing-account-number"
                />
              </Col>
              {profile && profile.serviceProvider !== EProviders.LECO && (
                <Col className="col-auto">
                  <AppButton
                    text={"Add billing id"}
                    iconName="add"
                    iconOnly
                    size="medium"
                    onClick={() => {
                      setShowAddUpdateAccountNumberModal(true);
                    }}
                  />
                </Col>
              )}
            </Row>
          )}
          {/* <div className="mt-4 d-flex align-items-center">
          <div className="me-2">Billing Space?</div>{" "}
          <AppSwitch checked={checked} handleClick={handleClick} />
        </div>
        {checked && (
          <AppInput
            className="mt-4"
            label="Billing Account number"
            placeholder="055997777351651"
            errors={errors}
            type="number"
          />
        )} */}
        </>
      </ModalContainer>
      <AddUpdateAccountNumberModal
        closeAddUpdateAccountNumberModal={() =>
          setShowAddUpdateAccountNumberModal(false)
        }
        onCancel={() => setShowAddUpdateAccountNumberModal(false)}
        onClose={() => setShowAddUpdateAccountNumberModal(false)}
        show={showAddUpdateAccountNumberModal}
        getAccountNumbers={getAccountNumbers}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={handleSubmit(onConfirmUpdateBillingSpaceCluster)}
        title="Update Billing Space"
        isLoading={isLoadingEditSpaceCluster}
      />
    </>
  );
};

export default AddUpdateSpaceModal;
