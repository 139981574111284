export enum EUserSharedStatus {
  ACTIVE = "ACTIVE",
  TERMINATED = "TERMINATED",
  EXPIRED = "EXPIRED",
}

export const userSharedStatusText = {
  [EUserSharedStatus.ACTIVE]: "Active",
  [EUserSharedStatus.TERMINATED]: "Terminated",
  [EUserSharedStatus.EXPIRED]: "Expired",
};
