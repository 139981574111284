import { ISpaceView } from "../interfaces/entities/space";

export const getDevicesLength = (space: ISpaceView): number => {
  let deviceCount = space.powerConsumers.length;

  space.childSpaces.forEach((childSpace) => {
    deviceCount += getDevicesLength(childSpace);
  });

  return deviceCount;
};
