import { Col, Row } from "react-bootstrap";
import { EConnectionStatus } from "../../oversight-core/enums/connection-status";
import AppSelect, {
  Option,
} from "../../oversight-core/ui-elements/app-select/app-select";
import AppToggle from "../../oversight-core/ui-elements/app-toggle/app-toggle";
import AppButton from "../../oversight-core/ui-elements/buttons/app-button/app-button";
import { generateTimeRangeList } from "../../oversight-core/utils/time-utils";
import AcController from "../ac-controller/ac-controller";
import styles from "./ac-schedule.module.scss";

interface ISchedule {
  id: number;
  time: string;
  isOn: boolean;
  temperature: number;
}

interface IProps {
  schedule: ISchedule;
  onRemove: () => void;
  onTimeChange: (time: Option) => void;
  onToggleChange: (isOn: boolean) => void;
  onTemperatureChange: (temperature: number) => void;
  scheduleCounter: number;
}

const AcSchedule = (props: IProps) => {
  const {
    schedule,
    onRemove,
    onTimeChange,
    onToggleChange,
    onTemperatureChange,
    scheduleCounter,
  } = props;

  const startTime = new Date("2000-01-01T00:00:00");
  const endTime = new Date("2000-01-02T00:00:00");
  const intervalMinutes = 30;

  return (
    <div className={`${styles.container} p-2`}>
      <Row className="align-items-center">
        <Col>
          <Row>
            <Col>
              <AppSelect
                options={generateTimeRangeList(
                  startTime,
                  endTime,
                  intervalMinutes
                )}
                menuHeight="22vh"
                placeholder="4:00 AM"
                isTimeSelection
                selectedValue={[{ value: schedule.time, label: schedule.time }]}
                onChangeOption={(selectedOption) =>
                  onTimeChange(selectedOption)
                }
                rightSideIcon="schedule"
              />
            </Col>
            <Col className="col-auto d-none d-md-block">
              <AppToggle
                isOn={schedule.isOn}
                onSwitch={() => onToggleChange(!schedule.isOn)}
              />
            </Col>
            <Col className="ps-4 d-none d-md-block">
              <AcController
                value={schedule.temperature}
                connectionState={EConnectionStatus.CONNECTED}
                onDecrease={() => onTemperatureChange(schedule.temperature - 1)}
                onIncrease={() => onTemperatureChange(schedule.temperature + 1)}
                isShowTemperatureText
              />
            </Col>
          </Row>
        </Col>
        {scheduleCounter > 1 && (
          <Col className="col-auto">
            <AppButton
              text="Remove"
              iconOnly
              iconName="close"
              variant="red"
              onClick={onRemove}
              size="other"
              iconSize={15}
            />
          </Col>
        )}
      </Row>
      <Row className="mt-3 mt-md-0 justify-content-between mx-0">
        <Col className="col-auto d-block d-md-none">
          <AppToggle
            isOn={schedule.isOn}
            onSwitch={() => onToggleChange(!schedule.isOn)}
          />
        </Col>
        <Col className="col-auto d-block d-md-none">
          <AcController
            value={schedule.temperature}
            connectionState={EConnectionStatus.CONNECTED}
            onDecrease={() => onTemperatureChange(schedule.temperature - 1)}
            onIncrease={() => onTemperatureChange(schedule.temperature + 1)}
            isShowTemperatureText
          />
        </Col>
      </Row>
    </div>
  );
};

export default AcSchedule;
