import moment from "moment";
import styles from "./days-of-week.module.scss";

interface IProps {
  disabledDays?: Date[];
  selectedDays?: Date[];
}

const DaysOfWeek = (props: IProps) => {
  const { disabledDays = [], selectedDays = [] } = props;

  const daysOfWeek = Array.from({ length: 7 }).map((_, i) =>
    moment().startOf("week").add(i, "days")
  );

  const isSameDay = (date1: moment.Moment, date2: Date) =>
    date1.isSame(moment(date2), "day");

  return (
    <div className={styles.daysOfWeek}>
      {daysOfWeek.map((day, index) => {
        const isSelected = selectedDays.some((selected) =>
          isSameDay(day, selected)
        );
        const isDisabled = disabledDays.some((disabled) =>
          isSameDay(day, disabled)
        );

        return (
          <div
            key={index}
            className={`${styles.day} ${isSelected ? styles.selected : ""} ${
              isDisabled ? styles.disabled : ""
            }`}
          >
            <div className={styles.dayLabel}>{day.format("ddd")[0]}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DaysOfWeek;
