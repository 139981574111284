import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyViewAllTemporaryUsersQuery } from "../../../redux/api/temp-user/tempUserAPI";
import IViewAllTemporaryUsersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-all-temporary-users-response-dto";
import {
  EUserSharedStatus,
  userSharedStatusText,
} from "../../../shared/oversight-core/enums/user-shared-status";
import { ISearchFilters } from "../../../shared/oversight-core/interfaces/search-filters";
import { ITemporaryUserHistoryView } from "../../../shared/oversight-core/interfaces/temporary-user-history-view";
import { ITemporaryUserView } from "../../../shared/oversight-core/interfaces/temporary-user-view";
import ButtonWithBadge from "../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import DataTable from "../../../shared/oversight-core/ui-elements/data-table/data-table";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import Pagination from "../../../shared/oversight-core/ui-elements/pagination/pagination";
import Search from "../../../shared/oversight-core/ui-elements/search/search";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import ShareUserViewModal from "./share-user-view-modal/share-user-view-modal";
import SharedUserHistoryListModal from "./shared-user-history-list-modal/shared-user-history-list-modal";

const headers = [
  {
    id: 1,
    columnName: "User",
    isSort: true,
    sortFunction: () => console.log("Sorting by User"),
    sort: false,
  },
  {
    id: 2,
    columnName: "Space",
  },
  {
    id: 3,
    columnName: "From",
    isSort: true,
    sortFunction: () => console.log("Sorting by From"),
    sort: false,
  },
  {
    id: 4,
    columnName: "To",
    isSort: true,
    sortFunction: () => console.log("Sorting by To"),
    sort: false,
  },
  {
    id: 5,
    columnName: "Mobile",
  },
  {
    id: 6,
    columnName: "Device",
  },
  {
    id: 7,
    columnName: "Status",
    isSort: true,
    sortFunction: () => console.log("Sorting by Status"),
    sort: false,
  },
  {
    id: 8,
    columnName: "",
  },
];

const initialFilters: ISearchFilters = {
  searchText: "",
  pageNumber: 0,
  pageSize: 10,
  ascending: true,
};

const SharedUserList = () => {
  const [filters, setFilters] = useState<ISearchFilters>({
    ...initialFilters,
  });
  const [searchText, setSearchText] = useState("");
  const [temporaryUsers, setTemporaryUsers] = useState<ITemporaryUserView[]>(
    []
  );
  const [totalElements, setTotalElements] = useState(0);
  const [showHistoryUserListModal, setShowHistoryUserListModal] =
    useState(false);
  const [historyList, setHistoryList] = useState<ITemporaryUserHistoryView[]>(
    []
  );
  const [sharedUser, setSharedUser] = useState<ITemporaryUserView>();
  const [showShareSpaceAccessModal, setShowShareSpaceAccessModal] =
    useState(false);
  const [updateCurrentStatus, setUpdateCurrentStatus] = useState(false);

  const [triggerAllTemporaryUsers, { isFetching }] =
    useLazyViewAllTemporaryUsersQuery();

  useEffect(() => {
    triggerAllTemporaryUsers({
      searchField: "",
      searchValue: filters.searchText,
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
      sortBy: "fromDate",
    })
      .unwrap()
      .then((res: IViewAllTemporaryUsersResponseDTO) => {
        setTemporaryUsers(res.temporaryUser.elements);
        setTotalElements(res.temporaryUser.totalElements);
      })
      .catch(() => {
        setTotalElements(0);
      });
  }, [triggerAllTemporaryUsers, filters, updateCurrentStatus]);

  const onSearch = () => {
    if (searchText) {
      setFilters({
        searchText: searchText,
        pageNumber: 0,
        pageSize: 10,
      });
    }
  };

  useEffect(() => {
    if (!searchText) {
      setFilters({ ...initialFilters });
    }
  }, [searchText]);

  return (
    <>
      <Row className="align-items-center justify-content-between">
        <Col className="col-8 col-md-6 col-lg-5 col-xl-3">
          <Search
            onSearch={onSearch}
            handleSearchChange={(searchText) => {
              setSearchText(searchText);
            }}
            value={searchText}
            placeholder="Search by Name of Space"
          />
        </Col>
        <Col className="col-auto">
          <ButtonWithBadge
            text="Filter"
            icon="filter_alt"
            onClick={() => console.log("Filter")}
            badgeValue={0}
          />
        </Col>
      </Row>
      <div className="container-white position-relative mt-4">
        <DataTable
          headers={headers}
          data={temporaryUsers.map((u) => ({
            data: [
              <>
                {u.firstName} {u.lastName}
              </>,
              <>{u.spaces[0].name}</>,
              <>{format(new Date(u.spaces[0].fromDate), "dd/MM/yyyy")}</>,
              <>{format(new Date(u.spaces[0].toDate), "dd/MM/yyyy")}</>,
              <>{u.contactNumber}</>,
              <>-</>,
              <>
                <div
                  className={
                    u.status === EUserSharedStatus.ACTIVE
                      ? `text-light-green`
                      : u.status === EUserSharedStatus.TERMINATED
                      ? `text-danger`
                      : `text-light`
                  }
                >
                  {userSharedStatusText[u.status]}
                </div>
              </>,
              <>
                <div className="d-flex justify-content-end gap-4">
                  <MaterialIcon
                    size={15}
                    icon="family_history"
                    color="#011F5D"
                    className="cursor-pointer"
                    onClick={() => {
                      setShowHistoryUserListModal(true);
                      setHistoryList(u.history);
                    }}
                  />
                  <MaterialIcon
                    size={18}
                    icon="visibility"
                    color="#011F5D"
                    className="cursor-pointer"
                    onClick={() => {
                      setShowShareSpaceAccessModal(true);
                      setSharedUser(u);
                    }}
                  />
                </div>
              </>,
            ],
          }))}
        />
        <Row className="mt-3">
          <Col>
            <Pagination
              itemsPerPage={filters.pageSize}
              length={totalElements}
              currentPage={filters.pageNumber + 1}
              updateCurrentPage={(pn) => {
                setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
              }}
            />
          </Col>
        </Row>
        <SpinnerModal show={isFetching} />
      </div>
      <SharedUserHistoryListModal
        show={showHistoryUserListModal}
        onClose={() => setShowHistoryUserListModal(false)}
        onCancel={() => setShowHistoryUserListModal(false)}
        history={historyList}
      />
      <ShareUserViewModal
        show={showShareSpaceAccessModal}
        onCancel={() => setShowShareSpaceAccessModal(false)}
        onClose={() => setShowShareSpaceAccessModal(false)}
        sharedUser={sharedUser}
        setUpdateCurrentStatus={setUpdateCurrentStatus}
      />
    </>
  );
};

export default SharedUserList;
