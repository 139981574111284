import { EDeviceTypes } from "../enums/device-types";

const findIcon = (deviceType: EDeviceTypes) => {
  switch (deviceType) {
    case EDeviceTypes.ALL_DEVICES:
      return "apps";
    case EDeviceTypes.TV:
      return "tv";
    case EDeviceTypes.REFRIGERATOR:
      return "kitchen";
    case EDeviceTypes.MICROWAVE_OVEN:
      return "microwave";
    case EDeviceTypes.BULB:
      return "lightbulb";
    case EDeviceTypes.COMPUTER:
      return "computer";
    case EDeviceTypes.HOME_THEATER:
      return "speaker_group";
    case EDeviceTypes.IRON:
      return "iron";
    case EDeviceTypes.WASHING_MACHINE:
      return "local_laundry_service";
    case EDeviceTypes.AIR_CONDITIONER:
      return "ac_unit";
    case EDeviceTypes.FAN:
      return "mode_fan";
    case EDeviceTypes.GENERAL:
      return "all_inclusive";
    case EDeviceTypes.WATER_DISPENSER:
      return "water_drop";
    case EDeviceTypes.RICE_COOKER:
      return "multicooker";
    case EDeviceTypes.INDUCTION_COOKER:
      return "stockpot";
    case EDeviceTypes.WATER_HEATER:
      return "water_heater";
    case EDeviceTypes.WATER_PUMP:
      return "water_pump";

    default:
      return "apps";
  }
};

export default findIcon;
