import { useState } from "react";
import Avatar from "react-avatar";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setPrivileges,
  setToken,
} from "../../../redux/features/auth/auth-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import AcController from "../../../shared/components/ac-controller/ac-controller";
import Logo from "../../../shared/oversight-core/assets/images/breezeLink-logo.svg";
import { EConnectionStatus } from "../../../shared/oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import LogoutModal from "../../../shared/oversight-core/shared-components/logout-modal/logout-modal";
import ActiveInactiveIndicator from "../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppDropDown from "../../../shared/oversight-core/ui-elements/app-dropdown/app-drop-down";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppToggle from "../../../shared/oversight-core/ui-elements/app-toggle/app-toggle";
import BulkAppToggle from "../../../shared/oversight-core/ui-elements/bulk-app-toggle/bulk-app-toggle";
import Gauge from "../../../shared/oversight-core/ui-elements/gauge/gauge";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../../shared/oversight-core/utils/findIcon";
import styles from "./breezeLink-temp-user.module.scss";
import ScheduleUsageModal from "./schedule-usage-modal/schedule-usage-modal";

const modeOptions: Option[] = [{ value: "Cool", label: "Cool" }];

const BreezeLinkTempUser = () => {
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedMode, setSelectedMode] = useState({ ...modeOptions[0] });
  const [showScheduleUsageModal, setShowScheduleUsageModal] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  const logOutHandler = () => {
    localStorage.clear();
    dispatch(setToken(""));
    dispatch(setPrivileges([]));
    navigate(AppRoute.ACCESS_BREEZE_LINK);
  };

  return (
    <div className={styles.container}>
      <Container fluid className="py-4 px-4 px-sm-5">
        <Row className="align-items-center justify-content-between">
          <Col className="order-2 order-sm-1 col-auto col-sm">
            <img
              src={Logo}
              alt="BreezeLink Logo"
              onClick={() => navigate(AppRoute.TEMP_USER_DASHBOARD)}
            />
          </Col>
          <Col className="col-auto order-1 order-sm-2">
            <Row className="align-items-center">
              <Col className="col-auto pe-3 order-2 order-sm-1">
                <MaterialIcon
                  icon="notifications"
                  size={25}
                  className="cursor-pointer"
                />
              </Col>
              <Col className="col-auto px-0 order-1 order-sm-2">
                <Avatar
                  name={`${profile?.firstName} ${profile?.lastName}`}
                  size="40"
                  round={true}
                  className="cursor-pointer"
                />
              </Col>
              <Col className="text-dark font-weight-500 font-size-14 d-none d-sm-block order-3">
                {profile?.firstName}
                <br /> {profile?.lastName}
              </Col>
              <Col className="col-auto order-4">
                <AppDropDown
                  items={[
                    {
                      text: "Profile",
                      onClick: () => {
                        navigate(AppRoute.TEMP_USER_PROFILE);
                      },
                    },
                    {
                      text: "Log Out",
                      onClick: () => setShowLogout(true),
                    },
                  ]}
                  icon="expand_more"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center justify-content-md-between mt-4 mx-0">
          <Col className="col-12 col-md">
            <Row className="align-items-center justify-content-center justify-content-md-start">
              <Col className="col-auto">
                <BulkAppToggle
                  status={EDeviceStatus.BOTH}
                  onSwitch={(powerState: EDeviceStatus) => {}}
                />
              </Col>
              <Col className="col-auto">
                <Row>
                  <Col
                    className="text-dark font-weight-500 font-size-32"
                    style={{ lineHeight: "1.2" }}
                  >
                    Room 1
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="text-light font-weight-400 font-size-16"
                    style={{ lineHeight: "1.2" }}
                  >
                    2 Devices
                  </Col>
                </Row>
              </Col>
              <Col>
                <AppDropDown
                  items={[
                    {
                      text: "Room 2",
                      onClick: () => {
                        console.log("Room 1");
                      },
                    },
                    {
                      text: "Room 3",
                      onClick: () => {
                        console.log("Room 2");
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-auto mt-4 mt-md-0 text-center text-md-start">
            <Row>
              <Col className="text-light font-weight-400 font-size-14">
                Access Available Until
              </Col>
            </Row>
            <Row>
              <Col className="text-dark font-weight-500 font-size-16">
                24 March 2024, 11:00am
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={`${styles.hr} my-4`}></div>
        <Row className="mx-0">
          <Col className={`${styles.deviceCard} p-3`}>
            <Row className="align-items-center">
              <Col className="ms-2">
                <Row className="align-items-center">
                  <Col
                    className={`${EDeviceTypes.AIR_CONDITIONER} p-3 rounded col-auto`}
                  >
                    <MaterialIcon
                      icon={findIcon(EDeviceTypes.AIR_CONDITIONER)}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Col className="text-dark font-weight-500 font-size-20">
                        Room 1 AC
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col className="col-auto pe-0">
                        <ActiveInactiveIndicator isActive />
                      </Col>
                      <Col className="col-auto text-light font-weight-400 font-size-14 ps-2">
                        450 W
                      </Col>
                      <Col className="col-auto text-light font-weight-400 font-size-14 ps-1">
                        Room 1
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="col-auto">
                <MaterialIcon
                  icon="expand_more"
                  color="#69768B"
                  className="cursor-pointer"
                  size={30}
                  fill
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center gap-0 gap-lg-5 gap-xl-0 mt-5">
          <Col className="col-12 col-md-auto">
            <Row
              className={`${styles.deviceCardDetails} justify-content-between p-2`}
            >
              <Col className="col-auto">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-16">
                    Device Type
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-16">
                    AC
                  </Col>
                </Row>
              </Col>
              <Col className="col-auto">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-16">
                    Brand
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-16">
                    LG
                  </Col>
                </Row>
              </Col>
              <Col className="col-auto">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-16">
                    Model
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-16">
                    AC234M
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center mt-5">
              <Col className="col-auto d-block d-sm-none">
                <Gauge
                  value={24}
                  maxValue={100}
                  pathColor="#02205D"
                  trailColor="#E2E6F1"
                  width={300}
                  height={150}
                  fontSizeValue={55}
                />
              </Col>
              <Col className="col-auto d-none d-sm-block">
                <Gauge
                  value={24}
                  maxValue={100}
                  pathColor="#02205D"
                  trailColor="#E2E6F1"
                  width={400}
                  height={200}
                  fontSizeValue={55}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-md-7 col-lg-6 offset-xl-1 mt-5 pt-3 mt-lg-0">
            <Row className="align-items-end justify-content-between">
              <Col className="col-6 col-sm-6 col-xxl-4">
                <AcController
                  value={24}
                  connectionState={EConnectionStatus.CONNECTED}
                  onDecrease={() => {}}
                  onIncrease={() => {}}
                  isTempUserView
                  fontSize={22}
                  btnPadding={10}
                />
              </Col>
              <Col className="col-auto pe-0">
                <AppToggle isOn label="Socket" />
              </Col>
              <Col className="col-auto pe-0">
                <AppToggle isOn label="AC Controller" />
              </Col>
            </Row>
            <Row className="align-items-end justify-content-between mt-5">
              <Col className="col-6 col-sm-6 col-xxl-4">
                <AcController
                  icon="mode_fan"
                  value={2}
                  connectionState={EConnectionStatus.CONNECTED}
                  onDecrease={() => {}}
                  onIncrease={() => {}}
                  isTemperature={false}
                  isTempUserView
                  fontSize={22}
                  btnPadding={10}
                />
              </Col>
              <Col className="col-auto col-sm-4 col-md-3 pe-0">
                <AppSelect
                  icon="ac_unit"
                  label="Mode"
                  selectedValue={selectedMode}
                  options={modeOptions}
                  onChangeOption={(selectedOption) =>
                    setSelectedMode(selectedOption)
                  }
                />
              </Col>
            </Row>
            <Row className="mt-5 mb-4 mb-xl-0 pt-4">
              <Col className={`${styles.scheduleUsageContainer} pb-2`}>
                <Row>
                  <Col
                    className={`${styles.scheduleUsage} px-3 py-2 cursor-pointer`}
                    onClick={() => setShowScheduleUsageModal(true)}
                  >
                    <Row className="align-items-center">
                      <Col className="col-auto pe-0">
                        <MaterialIcon icon="autoplay" color="#69768B" />
                      </Col>
                      <Col className="text-light font-weight-500 font-size-12">
                        Schedule Usage
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="align-items-center mt-2 px-1">
                  <Col className="text-light font-weight-500 font-size-12">
                    <Row>
                      <Col className="col-auto pe-0">Scheduled</Col>
                      <Col className="col-auto px-1">
                        <MaterialIcon
                          icon="keyboard_double_arrow_right"
                          color="#69768B"
                          size={18}
                        />
                      </Col>
                      <Col className="col-auto ps-0">09:00 | ON | 19 oC</Col>
                    </Row>
                  </Col>
                  <Col className="col-auto">
                    <Row className="align-items-center">
                      <Col className="col-auto pe-0 ">
                        <MaterialIcon
                          icon="close_small"
                          color="#AA1821"
                          size={18}
                          className="cursor-pointer"
                        />
                      </Col>
                      <Col className="text-danger font-weight-400 font-size-14 ps-1 cursor-pointer">
                        Remove
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ScheduleUsageModal
        show={showScheduleUsageModal}
        onClose={() => setShowScheduleUsageModal(false)}
        onCancel={() => setShowScheduleUsageModal(false)}
      />
      <LogoutModal
        show={showLogout}
        onCancel={() => setShowLogout(false)}
        onClose={() => setShowLogout(false)}
        onConfirm={() => {
          setShowLogout(false);
          logOutHandler();
        }}
      />
    </div>
  );
};

export default BreezeLinkTempUser;
