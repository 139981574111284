import { Col, Row } from "react-bootstrap";
import { EConnectionStatus } from "../../oversight-core/enums/connection-status";
import MaterialIcon, {
  MtIcon,
} from "../../oversight-core/ui-elements/material-icon/material-icon";
import styles from "./ac-controller.module.scss";

interface IProps {
  value: number;
  isShowTemperatureText?: boolean;
  connectionState: EConnectionStatus;
  onIncrease: (event: React.MouseEvent<HTMLElement>) => void;
  onDecrease: (event: React.MouseEvent<HTMLElement>) => void;
  icon?: MtIcon;
  isTemperature?: boolean;
  isTempUserView?: boolean;
  fontSize?: number;
  btnPadding?: number;
}

const AcController = (props: IProps) => {
  const {
    value,
    isShowTemperatureText,
    connectionState,
    onIncrease,
    onDecrease,
    icon = "device_thermostat",
    isTemperature = true,
    isTempUserView = false,
    fontSize = 18,
    btnPadding = 6,
  } = props;

  return (
    <Row
      className={`align-items-center ${
        connectionState === EConnectionStatus.DISCONNECTED ? `opacity-50` : ``
      }`}
    >
      <Col
        className={`col-auto ${styles.remove} ${
          connectionState === EConnectionStatus.CONNECTED
            ? `cursor-pointer`
            : `cursor-disabled`
        }`}
        style={{ padding: `${btnPadding}px` }}
      >
        <MaterialIcon
          icon="remove"
          color="#69768B"
          size={15}
          onClick={onDecrease}
        />
      </Col>
      <Col
        className={`${isTempUserView ? `col-6 col-sm-7` : `col-auto`} ${
          !isShowTemperatureText && `px-4`
        }`}
      >
        <Row className="flex-column align-items-center justify-content-center">
          <Col className="col-auto" style={{ lineHeight: "1" }}>
            <Row className="align-items-center">
              <Col className="col-auto px-0">
                <MaterialIcon icon={icon} color="#011F5D" size={12} />
              </Col>
              <Col
                className={`px-0 font-weight-600 text-primary font-size-${fontSize} col-auto`}
              >
                {value}
                {isTemperature && (
                  <span className="font-weight-400 text-primary font-size-11 ps-1">
                    &#8451;
                  </span>
                )}
              </Col>
            </Row>
          </Col>
          {isShowTemperatureText && (
            <Col
              className="col-auto text-light font-weight-400 font-size-14"
              style={{ lineHeight: "1" }}
            >
              Temperature
            </Col>
          )}
        </Row>
      </Col>
      <Col
        className={`col-auto ${styles.remove} ${
          connectionState === EConnectionStatus.CONNECTED
            ? `cursor-pointer`
            : `cursor-disabled`
        }`}
        style={{ padding: `${btnPadding}px` }}
      >
        <MaterialIcon
          icon="add"
          color="#69768B"
          size={15}
          onClick={onIncrease}
        />
      </Col>
    </Row>
  );
};

export default AcController;
