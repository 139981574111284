import { format } from "date-fns";
import { Col, Row } from "react-bootstrap";
import {
  EUserSharedStatus,
  userSharedStatusText,
} from "../../../../shared/oversight-core/enums/user-shared-status";
import { ITemporaryUserHistoryView } from "../../../../shared/oversight-core/interfaces/temporary-user-history-view";
import DataTable from "../../../../shared/oversight-core/ui-elements/data-table/data-table";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  history: ITemporaryUserHistoryView[];
}

const SharedUserHistoryListModal = (props: IProps) => {
  const { show, history, ...rest } = props;

  return (
    <ModalContainer
      title="Shared User History"
      {...rest}
      show={show}
      size="modal-lg"
      isButtonsVisible={false}
    >
      <>
        {history.length > 0 ? (
          <>
            <Row>
              <Col>
                <DataTable
                  headers={[
                    { id: 1, columnName: "Space" },
                    { id: 2, columnName: "From" },
                    { id: 3, columnName: "To" },
                    { id: 4, columnName: "Mobile" },
                    { id: 5, columnName: "Status" },
                  ]}
                  data={history.map((item) => {
                    return {
                      data: [
                        <>{item.spaces[0].name}</>,
                        <>
                          {format(
                            new Date(item.spaces[0].fromDate),
                            "dd/MM/yyyy"
                          )}
                        </>,
                        <>
                          {format(
                            new Date(item.spaces[0].toDate),
                            "dd/MM/yyyy"
                          )}
                        </>,
                        <>{item.contactNumber}</>,
                        <>
                          <div
                            className={
                              item.status === EUserSharedStatus.ACTIVE
                                ? `text-light-green`
                                : item.status === EUserSharedStatus.TERMINATED
                                ? `text-danger`
                                : `text-light`
                            }
                          >
                            {userSharedStatusText[item.status]}
                          </div>
                        </>,
                      ],
                    };
                  })}
                ></DataTable>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mx-0">
            <Col className="container-dash text-center text-light font-weight-400 font-size-14 mt-3">
              There are no shared user history
            </Col>
          </Row>
        )}
      </>
    </ModalContainer>
  );
};

export default SharedUserHistoryListModal;
