import IRegisterTemporaryUserRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/register-remporary-user-request-dto";
import IUpdateTemporaryUserRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/update-temporary-user-request-dto";
import LoginResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/login-response-dto";
import IRegenerateTemporaryUserVerificationCodeResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/regenerate-temporary-user-verification-code-response-dto";
import IRegisterTemporaryUserResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/register-temporary-user-response-dto";
import IUpdateAcControllerFanSpeedByIdResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/update-ac-controller-fan-speed-by-id-response-dto";
import IUpdateAcControllerModeByIdResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/update-ac-controller-mode-by-id-response-dto";
import IViewAllTemporaryUsersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-all-temporary-users-response-dto";
import IViewTemporaryUsersBySpaceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-temporary-users-by-space-response-dto";
import { EACFanSpeed } from "../../../shared/oversight-core/enums/ac-fan-speed";
import { EACMode } from "../../../shared/oversight-core/enums/ac-mode";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { setPrivileges, setToken } from "../../features/auth/auth-slice";
import { rootApi } from "../apiManager";

export const tempUserAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    temporaryUserLogin: builder.mutation<
      LoginResponseDTO,
      { verificationCode: string }
    >({
      query({ verificationCode }) {
        return {
          url: "temporary/login",
          method: "POST",
          body: { verificationCode: verificationCode },
        };
      },
      transformErrorResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          localStorage.setItem("token", res.data.token);
          localStorage.setItem(
            "privileges",
            JSON.stringify(res.data.authorities)
          );
          dispatch(setToken(res.data.token));
          dispatch(setPrivileges(res.data.authorities));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    registerTemporaryUser: builder.mutation<
      IRegisterTemporaryUserResponseDTO,
      IRegisterTemporaryUserRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `user/temporary/register`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateTemporaryUser: builder.mutation<
      { userId: string },
      IUpdateTemporaryUserRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `user/temporary/update`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    terminateTemporaryUser: builder.mutation<
      void,
      {
        userIdentity: string;
        spaceClusterId: string;
        spaceId: string;
      }
    >({
      transformErrorResponse,
      query({ userIdentity, spaceClusterId, spaceId }) {
        return {
          url: `temporary/user/terminate`,
          method: "DELETE",
          params: { userIdentity, spaceClusterId, spaceId },
        };
      },
    }),
    regenerateTemporaryUserVerificationCode: builder.query<
      IRegenerateTemporaryUserVerificationCodeResponseDTO,
      {
        contactNumber: string;
      }
    >({
      transformErrorResponse,
      query({ contactNumber }) {
        return {
          url: `temporary/user/verification-code/regenerate`,
          method: "PATCH",
          body: { contactNumber: contactNumber },
        };
      },
    }),
    viewTemporaryUsersBySpace: builder.query<
      IViewTemporaryUsersBySpaceResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, spaceId }) {
        return {
          url: `space-cluster/${spaceClusterId}/space/${spaceId}/temporary/user/view`,
          method: "GET",
        };
      },
    }),
    viewAllTemporaryUsers: builder.query<
      IViewAllTemporaryUsersResponseDTO,
      {
        searchField?: string;
        searchValue?: string;
        pageNumber?: number;
        pageSize?: number;
        sortBy?: string;
        ascending?: boolean;
      }
    >({
      transformErrorResponse,
      query({
        searchField,
        searchValue,
        pageNumber,
        pageSize,
        sortBy,
        ascending,
      }) {
        return {
          url: `temporary/user/view/all`,
          method: "GET",
          params: {
            searchField: searchField,
            searchValue: searchValue,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortBy: sortBy,
            ascending: ascending,
          },
        };
      },
    }),
    updateModeOfAnACController: builder.mutation<
      IUpdateAcControllerModeByIdResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        acControllerId: string;
        updatingMode: EACMode;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/ac-controller/${data.acControllerId}/mode`,
          method: "PUT",
          params: {
            updatingMode: data.updatingMode,
          },
        };
      },
    }),
    updateFanSpeedOfAnACController: builder.mutation<
      IUpdateAcControllerFanSpeedByIdResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        acControllerId: string;
        updatingFanSpeed: EACFanSpeed;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/ac-controller/${data.acControllerId}/fan-speed`,
          method: "PUT",
          params: {
            updatingFanSpeed: data.updatingFanSpeed,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useTemporaryUserLoginMutation,
  useRegisterTemporaryUserMutation,
  useUpdateTemporaryUserMutation,
  useTerminateTemporaryUserMutation,
  useLazyRegenerateTemporaryUserVerificationCodeQuery,
  useLazyViewTemporaryUsersBySpaceQuery,
  useLazyViewAllTemporaryUsersQuery,
  useUpdateModeOfAnACControllerMutation,
  useUpdateFanSpeedOfAnACControllerMutation,
} = tempUserAPI;
