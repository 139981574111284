import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import IBillingSpaceFilter from "../../../shared/oversight-core/interfaces/billing-space-filter";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import { RootState } from "../../store";

export const defaultSpaceViewBillingSpaceFilter: ISpaceView = {
  id: "",
  name: "",
  parentSpaceId: "",
  powerConsumers: [],
  sharedStatus: false,
  childSpaces: [],
  clusterId: "",
  tariffCode: "",
  accountNumber: "",
  accountNumberLabel: "",
  smartDevices: [],
  powerState: EDeviceStatus.OFF,
};

const defaultSelectedSpaceCluster: ISpaceCluster = {
  id: "",
  label: "",
  serviceProviderAccount: { label: "", accountNumber: "", tariffCode: "" },
  rootSpace: defaultSpaceViewBillingSpaceFilter,
};

const defaultBillingSpaceFilter: IBillingSpaceFilter = {
  spaceCluster: defaultSelectedSpaceCluster,
  isSpaceClusterListChanged: false,
  newClusterId: "",
};

export const billingSpaceFilterSlice = createSlice({
  name: "billingSpaceFilter",
  initialState: { ...defaultBillingSpaceFilter },
  reducers: {
    setBillingSpaceFilter: (state, action: PayloadAction<ISpaceCluster>) => {
      state.spaceCluster.id = action.payload.id;
      state.spaceCluster.label = action.payload.label;
      state.spaceCluster.rootSpace = { ...action.payload.rootSpace };
      state.spaceCluster.serviceProviderAccount = {
        ...action.payload.serviceProviderAccount,
      };
    },
    resetBillingSpaceFilter: (state) => {
      state.spaceCluster.id = "";
      state.spaceCluster.label = "";
      state.spaceCluster.serviceProviderAccount = {
        label: "",
        accountNumber: "",
        tariffCode: "",
      };
      state.spaceCluster.rootSpace = defaultSpaceViewBillingSpaceFilter;
    },
    setIsSpaceClusterListChanged: (state) => {
      state.isSpaceClusterListChanged = !state.isSpaceClusterListChanged;
    },
    setNewClusterId: (state, action: PayloadAction<string>) => {
      state.newClusterId = action.payload;
    },
  },
});

export const {
  setBillingSpaceFilter,
  resetBillingSpaceFilter,
  setIsSpaceClusterListChanged,
  setNewClusterId,
} = billingSpaceFilterSlice.actions;

export const selectBillingSpaceFilter = (state: RootState) =>
  state.billingSpaceFilter;

export default billingSpaceFilterSlice.reducer;
